<template>
    <div class="productWrap">
        <p>
            <!-- <span class="productTitle">设备日志</span> -->
            <router-link tag="a" target="_blank" to="/file">
                <span>查看文档</span>
            </router-link>
        </p>
        <ul>
            <li class="flexBox" style="margin: 20px 0">
                <a-select v-model="searchInfo.onProduct" style="width: 180px;">
                    <a-select-option value="3">
                        所属产品（全部）
                    </a-select-option>
                    <a-select-option value="0">
                        产品1
                    </a-select-option>
                    <a-select-option value="1">
                        产品2
                    </a-select-option>
                    <a-select-option value="2">
                        产品3
                    </a-select-option>
                </a-select>
                <a-select v-model="searchInfo.type" style="width: 180px;">
                    <a-select-option value="2">
                        业务类型（全部）
                    </a-select-option>
                    <a-select-option value="0">
                        设备行为
                    </a-select-option>
                    <a-select-option value="1">
                        上行消息
                    </a-select-option>
                    <a-select-option value="3">
                        下行消息
                    </a-select-option>
                    <a-select-option value="4">
                        物模型调用
                    </a-select-option>
                    <a-select-option value="5">
                        规则引擎
                    </a-select-option>
                    <a-select-option value="6">
                        HTTP推送
                    </a-select-option>
                    <a-select-option value="7">
                        MQ推送
                    </a-select-option>
                    <a-select-option value="8">
                        开发API
                    </a-select-option>
                    <a-select-option value="9">
                        应用长连接
                    </a-select-option>
                </a-select>
                <a-select v-model="searchInfo.status" style="width: 180px;">
                    <a-select-option value="2">
                        日志状态（全部）
                    </a-select-option>
                    <a-select-option value="0">
                        成功
                    </a-select-option>
                    <a-select-option value="1">
                        失败
                    </a-select-option>
                </a-select>
                <DateRangePicker :tip="['请选择开始日期', '请选择结束日期']" :dates="searchInfo.nowDates" @chooseDate="nowDate" @dateClear="dateClear" />
                <span class="equipSearch" @click="isVisible = true">点击查看消息链路</span>
            </li>
            <li class="flexBox">
                <a-input v-model="searchInfo.name" placeholder="按设备名称搜索" style="width: 300px" :allow-clear="true" enter-button />
                <a-input v-model="searchInfo.infoId" placeholder="按消息ID搜索" style="width: 300px;" :allow-clear="true" enter-button />
                <a-input v-model="searchInfo.id" placeholder="按链路ID搜索" style="width: 300px" :allow-clear="true" enter-button />
                <span class="equipSearch" @click="isShow = true">数据库查询工具</span>
            </li>
            <li class="equipBtn">
                <p style="color: orange; margin: 0">
                    * 系统默认展示近15分钟最新产品的数据，修改筛选条件后请点击"  查询 "按钮
                </p>
                <div>
                    <a-button style="margin: 0 20px" @click="onReset">
                        重置
                    </a-button>
                    <a-button type="primary" @click="onSearch">
                        查询
                    </a-button>
                </div>
            </li>
        </ul>
        <a-table style="margin-bottom: 20px"
                 :locale="{emptyText: '暂无数据'}"
                 table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="data" />
        <Pagination v-show="isShowPage" :count="total" @handleChange="changePage" @handleChangeSize="changeSize" />
        <!-- 消息链路详情弹窗 -->
        <a-modal
            title="消息链路详情"
            :width="710"
            :visible="isVisible"
            :centered="true"
            :footer="null"
            @cancel="isVisible = false">
            <img src="@/assets/img/infoLink.png">
        </a-modal>
        <!-- 数据库查询工具弹窗 -->
        <a-modal
            title="数据库查询工具"
            :width="1100"
            :visible="isShow"
            :centered="true"
            :footer="null"
            @cancel="isShow = false">
            <div class="toolWrap">
                <ul class="toolChooseWrap">
                    <li>
                        <a-select v-model="toolChoose.database" style="width: 160px" placeholder="choose database">
                            <a-select-option value="1">
                                db1 (influxDB)
                            </a-select-option>
                            <a-select-option value="2">
                                db2 (influxDB)
                            </a-select-option>
                            <a-select-option value="3">
                                db3 (mySQL)
                            </a-select-option>
                        </a-select>
                    </li>
                    <li>
                        <a-select v-model="toolChoose.action" style="width: 160px" placeholder="choose action">
                            <a-select-option value="1">
                                SELECT *
                            </a-select-option>
                            <a-select-option value="2">
                                SELECT "&lt;field_key&gt;"
                            </a-select-option>
                            <a-select-option value="3">
                                SELECT "&lt;tag_key&gt;"
                            </a-select-option>
                        </a-select>
                    </li>
                    <li>
                        <a-select v-model="toolChoose.style" style="width: 160px" placeholder="choose style">
                            <a-select-option value="1">
                                from
                            </a-select-option>
                            <a-select-option value="2">
                                as
                            </a-select-option>
                        </a-select>
                    </li>
                    <li>
                        <a-select v-model="toolChoose.measurement" style="width: 160px" placeholder="choose database">
                            <a-select-option value="1">
                                measurement a
                            </a-select-option>
                            <a-select-option value="2">
                                measurement b
                            </a-select-option>
                            <a-select-option value="3">
                                measurement c
                            </a-select-option>
                        </a-select>
                    </li>
                    <li>
                        <a-select v-model="toolChoose.condition" style="width: 160px" placeholder="choose database">
                            <a-select-option value="1">
                                where
                            </a-select-option>
                            <a-select-option value="2">
                                group by
                            </a-select-option>
                            <a-select-option value="3">
                                group by time
                            </a-select-option>
                        </a-select>
                    </li>
                </ul>
                <div style="flex: 1">
                    <a-textarea
                        v-model="toolChoose.txt"
                        placeholder="选择数据库XXX"
                        style="resize: none; height: 100%" />
                    <a-button type="primary" style="width: 80px; margin-top: 20px">
                        RUN
                    </a-button>
                </div>
            </div>
            <a-table style="margin: 80px 0 20px 30px"
                     :locale="{emptyText: '暂无数据'}"
                     table-layout="auto" :columns="columns1" :pagination="false" row-key="id" :data-source="data1" />
        </a-modal>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import DateRangePicker from "@/components/DateRangePicker"
import { productList } from "@/api/product"
import { getEquipList, deleteEquip, changeEquip, searchEquip, deleteEquipList } from "@/api/equipment"
import { validateRules } from "@/utils/validate"
// import moment from "moment";
const columns = [
    {
        title: '日志时间',
        dataIndex: 'createTime',
        key: 'createTime'
    },
    {
        title: '设备名称',
        dataIndex: 'productName',
        key: 'toProduct'
    },
    {
        title: '组件来源',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: '链路ID',
        dataIndex: 'imei',
        key: 'IMEI'
    },
    {
        title: '消息ID',
        dataIndex: 'nodeType',
        key: 'id'
    },
    {
        title: '业务类型',
        dataIndex: 'source',
        key: 'source'
    },
    {
        title: '日志状态',
        dataIndex: 'createTime',
        key: 'addTime'
    },
    {
        title: '日志内容',
        dataIndex: 'latestOnlineTime',
        key: 'onlineTime'
    }
]
const columns1 = [
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: 'code',
        dataIndex: 'code',
        key: 'code'
    },
    {
        title: 'create_time',
        dataIndex: 'create_time',
        key: 'create_time'
    },
    {
        title: 'update_time',
        dataIndex: 'update_time',
        key: 'update_time'
    },
    {
        title: 'create_by',
        dataIndex: 'create_by',
        key: 'create_by'
    },
    {
        title: 'create_id',
        dataIndex: 'create_id',
        key: 'create_id'
    },
    {
        title: 'update_by',
        dataIndex: 'update_by',
        key: 'update_by'
    },
    {
        title: 'update_id',
        dataIndex: 'update_id',
        key: 'update_id'
    },
    {
        title: 'version',
        dataIndex: 'version',
        key: 'version'
    },
    {
        title: 'is_delete',
        dataIndex: 'is_delete',
        key: 'is_delete'
    },
    {
        title: 'dev',
        dataIndex: 'dev',
        key: 'dev'
    }
]
const data1 = [
    {
        id: 1,
        code: 'kkk',
        create_time: '2021-1-1',
        update_time: '2021-2-1',
        create_by: 'sdfs2021',
        create_id: '1',
        update_by: 'system',
        update_id: '1',
        version: '11',
        is_delete: '0',
        dev: 'sss'
    },
    {
        id: 2,
        code: 'kkk',
        create_time: '2021-1-1',
        update_time: '2021-2-1',
        create_by: 'sdfs2021',
        create_id: '1',
        update_by: 'system',
        update_id: '1',
        version: '11',
        is_delete: '0',
        dev: 'sss'
    },
    {
        id: 3,
        code: 'kkk',
        create_time: '2021-1-1',
        update_time: '2021-2-1',
        create_by: 'sdfs2021',
        create_id: '1',
        update_by: 'system',
        update_id: '1',
        version: '11',
        is_delete: '0',
        dev: 'sss'
    },
    {
        id: 4,
        code: 'kkk',
        create_time: '2021-1-1',
        update_time: '2021-2-1',
        create_by: 'sdfs2021',
        create_id: '1',
        update_by: 'system',
        update_id: '1',
        version: '11',
        is_delete: '0',
        dev: 'sss'
    },
    {
        id: 5,
        code: 'kkk',
        create_time: '2021-1-1',
        update_time: '2021-2-1',
        create_by: 'sdfs2021',
        create_id: '1',
        update_by: 'system',
        update_id: '1',
        version: '11',
        is_delete: '0',
        dev: 'sss'
    }
]
export default {
    name: "Index",
    components: {
        Pagination,
        DateRangePicker
    },
    data() {
        return {
            // 当前分页码
            page: 1,
            // 每页数据条数
            size: 10,
            // 消息链路详情弹窗是否显示
            isVisible: false,
            // 数据库查询工具弹窗是否显示
            isShow: false,
            // 数据库查询工具选择项
            toolChoose: {
                database: undefined,
                action: undefined,
                style: undefined,
                measurement: undefined,
                condition: undefined,
                txt: ''
            },
            // 查询条件
            searchInfo: {
                onProduct: '3',
                type: '2',
                status: '2',
                name: '',
                infoId: '',
                id: '',
                nowDates: []
            },
            columns,
            data: [],
            columns1,
            data1,
            // 当前编辑的设备id
            equipId: '',
            // 当前编辑的设备code
            equipCode: '',
            productList: [],
            // 设备列表多选数据集合
            selectedRowKeys: [],
            // 当前编辑的设备所属产品code
            editProductCode: '',
            // 添加设备弹窗搜索的地址内容
            searchVal: '',
            // 编辑设备弹窗搜索的地址内容
            editSearchVal: '',
            // 设备数量集合
            list: {},
            // 所属产品code
            code: '',
            // 添加设备弹窗中选中的所属产品code
            toCode: '',
            // 添加设备弹窗中的地址
            site: {},
            // 编辑设备弹窗中的地址
            editSite: {},
            // 列表数据总条数
            total: 0,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 添加设备的弹窗是否可见
            visible: false,
            // 编辑设备的弹窗是否可见
            editVisible: false,
            // 单个添加表单数据
            form: {
                name: '',
                type: undefined,
                desc: ''
            },
            // 编辑设备表单数据
            editForm: {
                desc: ''
            },
            // 单个添加表单验证规则
            rules: {
                name: [
                    { required: true, message: '请输入设备code', trigger: 'blur' },
                    { validator: validateRules, trigger: 'blur' },
                ],
                type: [{ required: true, message: '请选择产品', trigger: 'change' }]
            },
            // 批量添加表单数据
            addForm: {
                type: undefined
            },
            // 批量添加表单验证规则
            addRules: {
                type: [{ required: true, message: '请选择产品', trigger: 'change' }]
            },
            isShowPage: true
        }
    },
    created() {
        this.visible = this.$route.query.fromPage !== undefined
        this.getProductList()
        this.getList(this.page, this.size)
    },
    methods: {
        // 获取产品列表数据
        getProductList() {
            productList().then(res => {
                this.productList = res.data
            }).catch(error => {
                console.log(error)
            })
        },
        // 获取设备列表数据
        getList(page, size, name, code, statu, mode) {
            getEquipList(page, size, name, code, statu, mode).then(res => {
                let info = res.data
                this.data = info.records
                this.total = info.total
                this.list = info.records[0] || { sumCount:0, onlineCount:0, offlineCount:0 }
            }).catch(error => {
                console.log(error)
            })
        },
        // 选择分页
        changePage(page) {
            this.reloadList(this, page, this.size)
        },
        // 修改每页数据条数
        changeSize(size) {
            this.size = size
            this.reloadList(this, 1, size)
        },
        // 产品名称下拉选择
        handleChange(val) {
            this.code = val
            this.reloadList(this, 1, this.size)
        },
        // 产品搜索
        onSearch() {
            this.reloadList(this, 1, this.size)
        },
        // 进入设备详情页
        gotoDetails() {
            this.$router.push('/equip-list/details?id=1')
        },
        // 编辑
        onEdit(val) {
            this.equipId = val.id
            this.equipCode = val.code
            searchEquip(val.id).then((res) => {
                let info = res.data
                this.editProductCode = info.productCode
                this.editSearchVal = info.address || ''
                this.editForm.desc = info.remark
                this.editSite = {
                    siteX: info.longitude || '',
                    siteY: info.latitude || '',
                    site: info.address || ''
                }
            }).catch(error => {
                console.log(error)
            })
            this.editVisible = true
        },
        // 选择编辑设备弹窗中的地址
        editChooseSite(val) {
            this.editSearchVal = val.title
            this.editSite = {
                siteX: val.point.lat,
                siteY: val.point.lng,
                site: val.title
            }
        },
        // 更新列表数据
        reloadList(that, page, size) {
            let status = parseInt(that.onProduct),
                mode = parseInt(that.type),
                code = that.code !== 'all' ? that.code : ''
            status = status !== 3 ? status : ''
            mode = mode !== 2 ? mode : ''
            that.getList(page, size, '', code, status, mode)
        },
        // 删除
        onDelete(val) {
            this.$confirm({
                title: '设备删除',
                content: '确认删除该设备吗',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    deleteEquip(val.id).then(() => {
                        this.$message.success('删除设备成功！')
                        this.reloadList(this, 1, this.size)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        },
        // 批量删除
        deleteEquipList() {
            if(this.selectedRowKeys.length > 0) {
                this.$confirm({
                    title: '设备删除',
                    content: '确认删除这些设备吗',
                    okText: '确定',
                    centered: true,
                    okType: 'danger',
                    cancelText: '取消',
                    onOk: () => {
                        deleteEquipList(this.selectedRowKeys).then(() => {
                            this.$message.success('删除设备成功！')
                            this.reloadList(this, 1, this.size)
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                })
            } else {
                this.$message.warn('请先选择要删除的设备!')
            }
        },
        // 设备列表中的多选事件
        onSelectChange(val) {
            this.selectedRowKeys = val
        },
        // 选择添加设备弹窗中的地址
        chooseSite(val) {
            this.searchVal = val.title
            this.site = {
                siteX: val.point.lat,
                siteY: val.point.lng,
                site: val.title
            }
        },
        // 点击添加设备弹窗中的确定按钮
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    changeEquip(this.form.name.trim(), this.form.type, this.site.siteX, this.site.siteY, this.site.site, this.form.desc).then(() => {
                        this.$message.success('设备添加成功！')
                        this.visible = false
                        this.$refs.ruleForm.resetFields()
                        this.searchVal = ''
                        this.getProductList()
                        this.reloadList(this, 1, this.size)
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        addChooseType() {},
        // 自定义列表中多选事件
        onChange(val) {
            let arr = [],
                arr1 = []
            val.forEach((node) => {
                this.columns.forEach((item, index) => {
                    if(item.dataIndex !== node) {
                        arr.push(index)
                    } else {
                        arr1.push(index)
                    }
                })
            })
            arr.forEach((item) => {
                if (item !== 9) {
                    this.columns[item].className = 'hidden'
                }
            })
            arr1.forEach((item) => {
                this.columns[item].className = ''
            })
            let flag = arr1.length === 0
            this.isShowPage = !flag
            if(flag) {
                this.columns.forEach((item) => {
                    item.className = 'hidden'
                })
            } else {
                this.columns.forEach((item) => {
                    if(item.title === '操作') {
                        item.className = ''
                    }
                })
            }
        },
        // 批量添加弹窗中的上传设备表格
        handleUpload(val) {
            console.log(val)
        },
        // 点击编辑设备弹窗中的确定按钮
        onEditSubmit() {
            this.$refs.editRuleForm.validate(valid => {
                if (valid) {
                    changeEquip(this.equipCode, '', this.editSite.siteX, this.editSite.siteY, this.editSite.site, this.editForm.desc, this.equipId).then(() => {
                        this.$message.success('设备编辑成功！')
                        this.editVisible = false
                        this.reloadList(this, 1, this.size)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 选择时间段
        nowDate(val) {
            console.log(val)
            // 格式化日期
            // this.onlineDate = {
            //     start: moment(val[0]).format('YYYY-MM-DD HH:mm:ss'),
            //     end: moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
            // }
        },
        dateClear(val) {
            if (val === 0) {}
            // this.onlineDate = {
            //     start: moment(val[0]).format('YYYY-MM-DD HH:mm:ss'),
            //     end: moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
            // }
        },
        // 重置操作
        onReset() {
            this.searchInfo = {
                onProduct: '3',
                type: '2',
                status: '2',
                name: '',
                infoId: '',
                id: '',
                nowDates: []
            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .hidden{
    display: none;
}
.productWrap {
    margin: 20px 40px;
    .productTitle {
        font-size: 20px;
        font-weight: bold;
        margin-right: 100px;
    }
}
.flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center
}
.equipBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}
.equipSearch {
    cursor: pointer;
    color: #1890ff;
    margin-left: 25px;
}
::v-deep .ant-pagination {
    text-align: right;
}
::v-deep .ant-pagination-total-text {
    float: left;
}
::v-deep .ant-table-thead > tr > th,
::v-deep .ant-table-tbody > tr > td {
    padding: 16px 10px;
}
.addInfo {
    display: flex;
    align-items: center;
    margin-left: 40px;
    .downLoad {
        color: #1890ff;
        margin-left: 30px;
        cursor: pointer;
    }
}
.card-container {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane > .ant-table-wrapper{
    background: #fff;
    padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
}
.equipList {
    display: flex;
    li {
        margin-left: 60px;
        text-align: center;
    }
    p {
        margin-bottom: 0;
    }
    .equipNum {
        font-size: 16px;
        font-weight: bold;
        margin-top: 14px;
    }
}
.toolWrap {
    display: flex;
    justify-content: space-between;
    .toolChooseWrap {
        margin: 0 30px 0 20px;
        li {
            margin-bottom: 30px;
        }
    }
}
</style>
